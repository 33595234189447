.containerAll {
    display: flex;
    flex-direction: column;
    
    width:100%;
    height:100vh;

    background-color: var(--gray);
    & > .camera  {
        width:100%;
    }
    & > button:not(.single){
        margin:0 10px;
        display:block;
        width:calc(100% - 20px);
    }
}

.container {
    cursor: pointer;
    width: 100px;
    padding: 1rem;
    z-index: 10;
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    margin-top: -1rem;
    margin-bottom: -1.5rem;

    button {
        width: 50%;
        padding: .7rem .5rem;
        margin-bottom: 6rem;

        text-align: center;
        font-size: 12px;
        font-weight: bold   ;
        
        line-height: 1.5715;

        border: none;
        border-radius: 0.25rem;

        margin-right: 5px;

        background-color: var(--background);
        color: var(--white);

        &:hover {
            filter: opacity(.8);
        }
    }
}