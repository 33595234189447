.container {
    height: 100vh;
  
    background-color: var(--background);

    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  
    img {
      margin: -100px;
      height: auto;
      width: 20rem;
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
  
    width: 100%;
    max-width: 700px;
  
    form {
      margin: 3.5rem 0;
      width: 340px;
      text-align: center;
    }
    .title {
      margin: var(--marginVertical);
      color: var(--white);
    }
  }
  
  .background {
    flex: 1;
    background: url('../assets/login.png') no-repeat center;
    background-size: cover;
  }
  .forgotpassword {
    text-align: right;
    p {
      text-decoration: none;
      cursor: pointer;
      font-size: 1rem;
      color: var(--white);
      &:hover {
        color: var(--white);
      }
    }
  }
  