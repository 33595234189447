.container {
    padding: 1rem 2rem;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 200px);
    position: relative;
}

.allowance-button {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto;
    padding: 1rem;
    justify-content: center;

    p {
        text-align: center;
        color: #666;
        width: 100%;
    }

    > div {
        width: 350px;
        flex: 0 0 350px;
        height: fit-content;
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.dateControls {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.uploadButton {
    margin-top: 1rem !important;
}

.fileName {
    font-size: 0.9em;
    color: #666;
    margin-top: 8px;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 5rem;

    gap: 1rem;
}

.preview {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    
    img {
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
}