.header {
    width: 100%;
    
    display: flex;
    align-items: center;
    
    padding: 1rem;

    background-color: var(--background);
    color: #fff;

    h2 {
        width: 100%;
        font-size: 1.7rem;

        margin-top: .5rem;
        margin-left: 1rem;

        svg {
            margin-bottom: .2rem;
        }
    }
}