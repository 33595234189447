.card {
    margin-top: 1rem;
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8.5rem;
    .cardContainer {
        width: 20rem;
        display: flex;
        padding: 1rem 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 1px 2px var(--background);
        .title {
            width: 100%;
            font-size: 1rem;
            margin-bottom: -1rem;
            text-align: left;
            padding-left: 2rem;
            padding-bottom: .5rem;
            font-weight: bold;
            color: #000;
        }
        .containerSubtitle {
            width: 100%;
            border-left: 1px solid #ccc;
        }
        .subtitle {
            width: 100%;
            font-size: 1rem;
            margin-left: 1.5rem;
            text-align: left;
            color: #000;
            padding: .5rem;
        }
    }
    .cardHome {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
}
.card2 {
    display: flex;
    flex-direction: column;
    width: calc(100% - 5rem);
    max-width: 45rem;
    height: 100%;
    margin-bottom: 1rem;
    background-color: var(--background);
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1rem;
    @media (min-width: 600px) {
        margin-right: 0.5rem;
    }
    .containerTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1rem;
        align-items: center;
        text-transform: capitalize;
        h5 {
            display: flex;
            font-weight: bold;
            font-size: 1.4rem;
            margin: 0;
        }
        span {
            margin: 0;
            font-weight: bold;
            font-size: 1.2rem;
            svg {
                margin-bottom: 2px;
            }
        }
        .details {
            cursor: pointer;
        }
    }
}
