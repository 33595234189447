.notify {
    width: 100%;
    p {
        font-size: 1rem;
        color: var(--gray);
    }
}

.notification {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.25rem;
    margin: 0 auto 1rem auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    cursor: pointer;
    width: 100%;
    border: 1px solid #eaeaea;
    position: relative;
    overflow: hidden;

    &.read {
        opacity: 0.7;
        background-color: #f9f9f9;
    }
}

.header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 0.75rem;

    .icon {
        flex-shrink: 0;
    }

    h3 {
        margin: 0;
        font-size: 1.2rem;
        font-weight: 600;
        color: #1a1a1a;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.message {
    margin: 0.75rem 0;
    color: #4a4a4a;
    line-height: 1.5;
    max-height: 120px;
    overflow: auto;
    font-size: 0.95rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #f0f0f0;

    small {
        color: #666;
        font-size: 0.85rem;
        font-weight: 500;
    }

    .readButton {
        background-color: var(--background);
        color: var(--white);
        border: none;
        border-radius: 6px;
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            filter: brightness(0.9);
        }
    }
}

.offices {
    margin: 0.75rem 0;
    
    span {
        color: #666;
        font-size: 0.9rem;
        font-weight: 500;
    }

    .officeList {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 0.5rem;
    }

    .officeBadge {
        background-color: var(--background);
        color: var(--white);
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        font-weight: 500;
    }
}