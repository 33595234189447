.container {
    margin-top: 1rem;
    img {
        max-width: 100%;
    }    
}
.containerHeader {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    display: flex;
    svg {
        cursor: pointer;
    }
}
.containerContent {
    p {
        font-weight: 600;
    }
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    margin-top: 2rem;

    button {
        width: 50%;
        padding: .7rem .5rem;

        text-align: center;
        font-size: 14px;
        font-weight: bold;
        
        line-height: 1.5715;

        border: none;
        border-radius: 0.25rem;

        margin-right: 5px;

        background-color: var(--background);
        color: var(--white);

        &:hover {
            filter: opacity(.8);
        }
    }

}
.containerTextArea {
    textarea {
    width: 100%;
    height: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    padding: .5rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5715;
    }
    
}