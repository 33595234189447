.container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .card {
    background: var(--background-card, #1e1e2f);
    margin-bottom: 5rem;

    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: var(--text-color, #fff);
    overflow: hidden;
    transition: transform 0.2s ease;
  
    &:hover {
      transform: translateY(-2px);
    }
  }
  
  .header {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
    .title {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin: 0;
      font-size: 1.75rem;
      font-weight: 600;
  
      svg {
        color: var(--primary-color, #4f46e5);
      }
    }
  }
  
  .content {
    padding: 1.5rem;
  }
  
  .dateControls {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
  
    @media (min-width: 640px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .dateGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  
    label {
      font-size: 1rem;
      font-weight: 500;
      color: var(--text-muted, rgba(255, 255, 255, 0.7));
    }
  
    input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.05);
      color: #fff;
      font-size: 1rem;
      transition: all 0.2s ease;
  
      &:focus {
        outline: none;
        border-color: var(--primary-color, #4f46e5);
        background: rgba(255, 255, 255, 0.1);
      }
  
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
        cursor: pointer;
      }
    }
  }
  
  .summary {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.75rem;
    padding: 1.5rem;
  
    .balance {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      margin-bottom: 1.25rem;
  
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
  
      &.positive {
        svg, .hours {
          color: var(--success-color, #22c55e);
        }
      }
  
      &.negative {
        svg, .hours {
          color: var(--error-color, #ef4444);
        }
      }
  
      .hours {
        font-size: 2.5rem;
        font-weight: 700;
      }
    }
  }
  
  .toggleButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s ease;
  
    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  
  .details {
    margin-top: 1.25rem;
  }
  
  .detailItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
    &:last-child {
      border-bottom: none;
    }
  
    .label {
      font-size: 1rem;
      color: var(--text-muted, rgba(255, 255, 255, 0.7));
    }
  
    .value {
      font-size: 1rem;
      font-weight: 500;
  
      &.positive {
        color: var(--success-color, #22c55e);
      }
    }
  }