.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    width: 100%;
    background-color: var(--background);
    color: #fff;
    h2 {
        font-size: 1.7rem;
    }
}

// .containerLayout {
//     margin-bottom: 56px;
// }

// .noBottomMargin {
//     margin-bottom: 0;
// }