.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    
}
.formGroup {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn {
    margin-top: 1rem;
    background-color: var(--background);
    color: var(--white);
    border: none;
    padding: 10px 50px;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: var(--primary);
    }
}



