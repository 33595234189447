.containerAll {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #1a1a1a;
    position: relative;
    align-items: center;
    justify-content: center;
}

.container {
    cursor: pointer;
    width: 100px;
    padding: 1rem;
    z-index: 10;
    position: absolute;
    top: 20px;
    left: 20px;
}

.cameraContainer {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 80vh;
    max-height: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #000;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    
    .camera {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        mask: radial-gradient(
            ellipse 140px 190px at 50% 50%,
            transparent 99%,
            black 100%
        );
        -webkit-mask: radial-gradient(
            ellipse 140px 190px at 50% 50%,
            transparent 99%,
            black 100%
        );
        pointer-events: none;
        z-index: 2;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        mask: radial-gradient(
            ellipse 140px 190px at 50% 50%,
            transparent 99%,
            black 100%
        );
        -webkit-mask: radial-gradient(
            ellipse 140px 190px at 50% 50%,
            transparent 99%,
            black 100%
        );
        pointer-events: none;
        z-index: 2;
    }
}

.faceGuide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    height: 380px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-radius: 180px/240px;
    pointer-events: none;
    transition: all 0.3s ease;
    z-index: 2;
    
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 260px;
        height: 360px;
        border: 2px dashed rgba(255, 255, 255, 0.4);
        border-radius: 170px/230px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 260px;
        height: 360px;
        background: transparent;
        border-radius: 170px/230px;
        z-index: 3;
        mask: radial-gradient(
            ellipse 130px 180px at 50% 50%,
            black 99%,
            transparent 100%
        );
        -webkit-mask: radial-gradient(
            ellipse 130px 180px at 50% 50%,
            black 99%,
            transparent 100%
        );
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }

    &.aligned {
        border-color: #4CAF50;
        box-shadow: 0 0 30px rgba(76, 175, 80, 0.4);
        &::before {
            border-color: rgba(76, 175, 80, 0.6);
        }
        &::after {
            box-shadow: 0 0 30px rgba(76, 175, 80, 0.4);
        }
    }
}

.feedbackText {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 12px 24px;
    border-radius: 20px;
    z-index: 3;
    max-width: 90%;
    margin: 0 auto;
    backdrop-filter: blur(4px);
}

.captureButtonContainer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 20;

    button {
        transform: scale(1.2);
        transition: all 0.2s ease;

        &:hover {
            transform: scale(1.3);
        }
    }
}

.previewContainer {
    width: 100%;
    max-width: 800px;
    height: 80vh;
    max-height: 800px;
    margin: 0 auto;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #000;
    border-radius: 20px;
}

.buttons {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0 20px;
    z-index: 20;

    button {
        flex: 1;
        max-width: 160px;
        padding: 15px;
        border: none;
        border-radius: 12px;
        background-color: var(--background);
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        font-size: 18px;

        &:hover {
            transform: scale(1.05);
            filter: brightness(1.1);
        }

        &:active {
            transform: scale(0.95);
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}

// Media queries para responsividade
@media (max-width: 768px) {
    .cameraContainer {
        width: 100%;
        height: 100vh;
        max-height: none;
        border-radius: 0;
        
        &::before, &::after {
            mask: radial-gradient(
                ellipse 100px 140px at 50% 50%,
                transparent 99%,
                black 100%
            );
            -webkit-mask: radial-gradient(
                ellipse 100px 140px at 50% 50%,
                transparent 99%,
                black 100%
            );
        }
    }

    .faceGuide {
        width: 200px;
        height: 280px;
        border-width: 2px;
        
        &::before {
            width: 180px;
            height: 260px;
            border-width: 1px;
        }
    }

    .previewImage {
        border-radius: 0;
    }

    .feedbackText {
        font-size: 16px;
        padding: 10px 20px;
    }

    .buttons button {
        max-width: 140px;
        padding: 12px;
    }
}

@media (max-width: 360px) {
    .cameraContainer {
        &::before, &::after {
            mask: radial-gradient(
                ellipse 90px 120px at 50% 50%,
                transparent 99%,
                black 100%
            );
            -webkit-mask: radial-gradient(
                ellipse 90px 120px at 50% 50%,
                transparent 99%,
                black 100%
            );
        }
    }

    .faceGuide {
        width: 180px;
        height: 250px;
        
        &::before {
            width: 160px;
            height: 230px;
        }
    }
}

// Ajustes para telas muito grandes
@media (min-width: 1440px) {
    .cameraContainer, .previewContainer {
        max-width: 1000px;
        max-height: 900px;
    }

    .faceGuide {
        width: 320px;
        height: 420px;
        
        &::before {
            width: 300px;
            height: 400px;
        }
    }

    .feedbackText {
        font-size: 20px;
        padding: 15px 30px;
    }
}

// Ajustes específicos para desktop
@media (min-width: 1024px) {
    .cameraContainer {
        border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .faceGuide {
        width: 320px;
        height: 420px;
        border-width: 4px;
        
        &::before {
            width: 300px;
            height: 400px;
            border-width: 3px;
        }
    }

    .feedbackText {
        font-size: 20px;
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.8);
    }
}