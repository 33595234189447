.container{
    display: flex;
    .content{
        display: flex;
        align-items: center;
        margin-top: .5rem;
        p {
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
}