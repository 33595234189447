.active-link{
    svg {
        color: var(--primary) !important;
    }
}
.link {
    color: var(--white)
}
.sidebar {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    a {
        color: var(--white);
        text-decoration: none;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        svg {
            margin-right: .5rem;
        }
    }
}
.img {
    width: 2.5rem;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
}