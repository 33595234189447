.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  h3 {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    font-weight: 600;
  }
}

.status {
  background: #e6f4ea;
  color: #1e8e3e;
  padding: 0.5rem 1rem; 
  border-radius: 20px; 
  font-size: 1rem; 
  font-weight: 600; 
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  
  span {
    color: #666;
    font-weight: 500;
    min-width: 120px;
  }
  
  p {
    color: #333;
    margin: 0;
    margin-left: auto;
  }
}

.attachment {
  margin-top: 1rem;
  align-self: center;
  
  img {
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
