.buttonStyle {
    background: linear-gradient(90deg, var(--primary) 0%, var(--title) 50%, var(--title) 0%);
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: var(--white);
    width: 100%;
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 2.5rem;
    transition: background-color 0.2s;
  
    &:hover {
      filter: brightness(0.9);
    }

    &[variant="outlined"] {
      background: transparent;
      border: 2px solid var(--primary);
      color: var(--primary);

      &:hover {
        background: var(--primary);
        color: var(--white);
      }
    }

    &[variant="text"] {
      background: transparent;
      color: var(--primary);
      
      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }

    &[variant="secondary"] {
      background: var(--background) !important;
      color: #fff;
      border-radius: 1rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      font-weight: bold;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }