.profile {
    margin: 0.5rem;
    margin-left: 1rem;
    .hour {
        text-align: right;
        font-size: 0.8rem;
        font-weight: bold;
        color: #665;
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .containerImg{
            margin-bottom: 1rem;
            margin-top: 1rem;
            
        }
        h3{
            font-size: 1.5rem;
        }
        p {
            font-size: 1rem;
            color: #665;
        }
    }
}
.sidebar {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
}