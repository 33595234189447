.card {
    display: flex;
    flex-direction: column;
    width: 25rem;
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    @media (min-width: 600px) {
        margin-right: 0.5rem;
    }
    .containerTitle {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: .5rem 0.2rem;
        align-items: center;
        margin-bottom: 1rem;
        border-bottom: 1px solid #e6e6e6;
        
        span {
            font-weight: 1000;
            font-size: 1rem;
        }
        .details {
            svg {
                margin-left: .5rem;
                font-size: 1.2rem;
            }
            .approved {
                color: var(--success);
            }
            .pending {
                color: var(--warning);
            }
            .rejected {
                color: var(--danger);
            }
        }
    }
    .containerContent {
        margin-top: .5rem;
        .content {
            display: flex;
            flex-direction: column;
            margin-bottom: .5rem;
            p {
                font-size: 1rem;
                display: flex;
                align-items: center;
                font-weight: bold;
                text-transform: capitalize;
            }
            .titleKindOfApproval {
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 1.1rem;
                    font-weight: bold;
                    text-transform: none;
                }
            }
            .address {
                display: flex;
                .pointTitle {
                    font-weight: bold;
                    text-transform: capitalize;
                }
            }
            .description {
                margin-left: 1.5rem;
            }
        }
        .buttons {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        
            margin-top: 2rem;
        
            button {
                width: 50%;
                padding: .7rem .5rem;
        
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                
                line-height: 1.5715;
        
                border: none;
                border-radius: 0.25rem;
        
                margin-right: 5px;
        
                background-color: var(--background);
                color: var(--white);
        
                &:hover {
                    filter: opacity(.8);
                }
            }
        
        }
        .obs {
            p {
                color: var(--danger);
                font-size: 1rem;
            }
        }
    }
}