.card {
    display: flex;
    flex-direction: column;
    width: 25rem;
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    @media (min-width: 600px) {
        margin-right: 0.5rem;
    }
    .containerTitle {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: .5rem 0.2rem;
        align-items: center;
        margin-bottom: 1rem;
        border-bottom: 1px solid #e6e6e6;
        
        span {
            font-weight: bold;
            font-size: 1rem;    
        }
         .details {
             svg {
                margin-left: .5rem;
                font-size: 1.2rem;
             }
         }
    }
    .containerContent {
        margin-top: .5rem;
        .content {
            display: flex;
            flex-direction: column;
            margin-bottom: .5rem;
            p {
                font-size: 1rem;
                display: flex;
                align-items: center;
                font-weight: bold;
                text-transform: capitalize;
            }
            .address {
                display: flex;
                .pointTitle {
                    font-weight: bold;
                    text-transform: capitalize;
                }
            }
            .description {
                margin-left: 1.5rem;
            }
        }
        .obs {
            p {
                color: var(--danger);
                font-size: 1rem;
            }
        }
    }
}
 