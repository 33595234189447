.container {
    padding: 1rem 2rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    max-width: 1200px;
}

.header {
    width: 100%;
    background-color: var(--background);
    color: #fff;
    padding: 1rem 0;
    text-align: center;
}

.loadingMore {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-style: italic;
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }
}
