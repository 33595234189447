.container {
    background: var(--light);
    border-radius: 10px;
    border: 2px solid #232129;
    padding: 16px;
    width: 100%;
    color: #312E38;
  
    display: flex;
    align-items: center;
  
    & + div {
      margin-top: 0.8rem;
    }
  
    input {
      flex: 1;
      background: transparent;
      border: 0 none;
      color: #312E38;
  
      &::placeholder {
        color: #312E38;
      }
  
      &:focus {
        box-shadow: 0 0 0 0;
        outline: 0
      }
  
      &:-internal-autofill-selected {
        background-color: #312E38  !important;
      }
  
  
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px #232129 inset;
      box-shadow: 0 0 0px 1000px #232129 inset;
    }
  
    i {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    svg {
      margin-right: 16px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .containerFocus {
    color: var(--primary);
    border-color: var(--primary);
  }
  
  .containerFilled {
    color: var(--primary);
  }
  