.profile {
    margin: 0.5rem;
    width: 100%;
    .hour {
        text-align: right;
        font-size: 0.8rem;
        font-weight: bold;
        color: #665;
    }
    .container {
        display: flex;
        flex-direction: row;
        padding: 0 2rem;
        width: 100%;
        height: 100%;

        .profileInfo {
            font-size: 1.2rem;
            color: #fff;
            h5 {
            font-weight: bold;
            }
            p {
                font-size: 0.88rem;
                color: rgb(226, 204, 204);
            }
        }

        .containerImg{
            margin-bottom: 1rem;

            img {
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 50%;
                margin-right: 1rem;
            }
        }
        .hours{
            align-self: center;
            font-size: 1rem;
            color: var(--white);
            p {
                margin: 0;
                text-align: left;
            }
        }
        
    }
}
.sidebar {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
}