.card {
    display: flex;
    flex-direction: column;
    width: calc(100% - 5rem);
    max-width: 45rem;
    height: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    @media (min-width: 600px) {
        margin-right: 0.5rem;
    }
    .containerTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: .5rem 1rem;
        align-items: center;
        margin-bottom: 1rem;
        border-bottom: 1px solid #e6e6e6;
        h3 {
            display: flex;
            font-weight: bold;
            text-transform: capitalize;
            span {
                margin-top: -2px;
                margin-left: .5rem;
            }
        }
        .details {
            cursor: pointer;
        }
    }
    .containerContent {
        margin-top: .5rem;
        .content {
            display: flex;
            align-items: center;
            margin-bottom: .5rem;
            p {
                font-size: 1rem;
                display: flex;
                align-items: center;
            }
            .address {
                display: flex;
                p {
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                }
            }
            .subtitle {
                margin: 0 auto;
                font-size: 1.2rem;
                color: #999;
                margin-top: .5rem;
                
            }
        }
    }
}
