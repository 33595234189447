.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 2 - 1rem);
    height: 7rem;
    background-color: var(--background);
    border-radius: .8rem;
    box-shadow: .5rem 0rem 1rem rgba(0, 0, 0, 0.4);
    margin: .5rem;
    overflow: auto;

    cursor: pointer;

    .cardTitle { 
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        svg {
            font-size: 1.7rem;
            margin-bottom: 0.5rem; 
        }
    }
}