.cam {
    position: fixed;
    bottom: 5.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        width: calc(100% - 5rem);
        max-width: 30rem;
        height: 4.5rem;
        margin-bottom: 1rem;
        background-color: var(--background);
        color: #fff;
        border-radius: 1rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}