.history {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    margin-top: 1.5rem;
    .cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 1.5rem;
    }

}

.containerLeave {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 22rem;
    padding: var(--padding-y);
    
    button {
        width: 10rem;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 1rem;

        position: absolute;
        bottom: 0; 
        margin-bottom: 4rem;
    }
}