@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #665;
    --gray-dark: #343a40;
    --primary: #007bff;
    --title: #133bb9;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --background: #070A1A;
    --background-white: #f8f9fa;
    --background-whiteAlpha: #e3e4ee;
    --background-button: #0c0a0a;
    --input-background-color: rgba(0,0,0,.05);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --border: rgba(236, 236, 236, 1);
    --padding-y: 1rem 2rem;
    --iconChecked: #28a745;
    --iconUnchecked: #dc3545;
    --iconCheckedDisabled: #007bff;
    --marginVertical: .5rem;
    --avatarSize: 80px;
    }
html {
    @media (max-width: 1080px) {
        font-size: 93.75%;
    }
    @media (max-width: 720px) {
        font-size: 87.5%;
    }
}
body {
    background: var(--background-white);
    -webkit-font-smoothing: antialiased;
    color: var(--shape);
}
body, input, textarea, button {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size:16px;   
}
h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
}
button {
    cursor: pointer;
}
